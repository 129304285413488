<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import { connectionTypes } from '@/modules/ReusePotentialIndex/queries';
import { lifespanOptions } from '@/modules/ReusePotentialIndex/lifespan';
import { getColumns } from '@/services/api/column.api';
import {
  createRecord,
  deleteRecord,
  deleteRecords,
  getRecordDocument,
  updateRecord,
} from '@/services/api/record.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

export default {
  name: 'ReusePotentialIndexCalculation',
  components: { AntInput },
  data: () => {
    return {
      newComponentMenu: false,
      newComponentName: '',
      components: [],
      componentsColumns: [],
      componentsTableId: '',
      componentsLoading: false,
      componentsListIndex: null,
      focusedComponent: null,
      componentConstructionDeviationDateMenu: false,

      connections: [],
      connectionsTableId: null,
      connectionsLoading: false,
      newConnectionMenu: false,
      newConnectionComponent: null,
      newConnectionType: null,

      focusedCrossSection: null,

      editMode: false,
    };
  },
  computed: {
    ...mapGetters(['project']),
    editable() {
      if (this.focusedComponent.status === 'created') {
        return true;
      }
      return this.focusedComponent.status === 'completed' && this.editMode;
    },
    componentLifespan() {
      return this.lifespanOptions().find(
        (x) => x.id === this.focusedComponent.technical_lifespan
      )?.label;
    },
  },
  watch: {
    focusedComponent(newValue, oldValue) {
      if (newValue) {
        if (newValue.id !== this.$route.params?.component) {
          this.$router.push({ params: { component: newValue.id } });
        }
        this.fetchComponentConnections();
        this.componentsListIndex = this.components.findIndex(
          (c) => c.id === this.$route.params.component
        );
      }
    },
  },
  async mounted() {
    await this.fetchComponents();
    if (this.$route.params.component) {
      this.focusedComponent = this.components.find(
        (c) => c.id === this.$route.params.component
      );
      this.componentsListIndex = this.components.findIndex(
        (c) => c.id === this.$route.params.component
      );
    }
  },
  methods: {
    lifespanOptions() {
      return lifespanOptions;
    },
    connectionTypes() {
      return connectionTypes;
    },
    async fetchComponents() {
      this.componentsLoading = true;
      try {
        let { components, crossSections } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_CROSS_SECTIONS',
              project: this.project.id,
              as: 'crossSections',
              sortBy: 'title',
              records: [this.$route.params.crossSection],
              columns: [
                {
                  name: 'variant',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.variant,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'image',
                },
                {
                  name: 'coverage_ratio',
                },
                {
                  name: 'score',
                },
                {
                  name: 'score_revision_time',
                },
              ],
            },
            {
              name: 'CFFA_RPI_COMPONENTS',
              project: this.project.id,
              as: 'components',
              sortBy: 'nr',
              columns: [
                {
                  name: 'cross_section',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.crossSection,
                    },
                  ],
                },
                {
                  name: 'nr',
                },
                {
                  name: 'name',
                },
                {
                  name: 'description',
                },
                {
                  name: 'status',
                },
                {
                  name: 'product_lvl',
                },
                {
                  name: 'component_function',
                },
                {
                  name: 'component_additional_function',
                },
                {
                  name: 'building_function',
                },
                {
                  name: 'building_additional_function',
                },
                {
                  name: 'generic_component',
                },
                {
                  name: 'construction_date_deviation',
                },
                {
                  name: 'technical_lifespan',
                },
              ],
            },
          ],
        });
        this.components = components.records;
        this.components.forEach((c) => {
          c.progress = this.getComponentProgress(c);
        });
        this.componentsTableId = components.id;
        const columns = await getColumns(this.project.id, components.id);

        this.componentsColumns = columns.map((c) => ({
          ...c,
          options_value: JSON.parse(c.options_value),
        }));

        this.focusedCrossSection = crossSections.records[0] ?? null;
        if (this.focusedCrossSection.image?.id) {
          this.focusedCrossSection.image = await getRecordDocument(
            this.focusedCrossSection.image.id,
            this.project.id,
            crossSections.id
          );
        }
      } catch (e) {}
      this.componentsLoading = false;
    },
    async saveComponent() {
      this.componentsLoading = true;
      try {
        let component = await createRecord({
          project: { id: this.project.id },
          table: {
            id: this.componentsTableId,
          },
          record: {
            cross_section: this.$route.params.crossSection,
            name: this.newComponentName,
            nr:
              this.components.length > 0
                ? ([...this.components].sort((a, b) => b.nr - a.nr)[0].nr + 1 ??
                  1)
                : 0,
            status: 'created',
          },
        });
        this.components.push(component);
        this.focusedComponent = component;
        this.componentsListIndex = this.components.findIndex(
          (c) => c.id === this.focusedComponent.id
        );
        this.newComponentMenu = false;
        this.newComponentName = '';
      } catch (e) {}
      this.componentsLoading = false;
    },
    completeComponent() {
      this.focusedComponent.status = 'completed';
      this.updateComponent();
    },
    async updateComponent() {
      this.componentsLoading = true;
      try {
        let component = await updateRecord(this.focusedComponent.id, {
          project: { id: this.project.id },
          table: { id: this.componentsTableId },
          record: this.focusedComponent,
        });
        component.progress = this.getComponentProgress(component);
        Object.assign(
          this.components.find((c) => c.id === this.focusedComponent.id),
          component
        );
        this.focusedComponent = { ...component };
      } catch (e) {}
      this.componentsLoading = false;
    },
    async fetchComponentConnections() {
      this.connectionsLoading = true;
      try {
        let { connections } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_COMPONENTS_CONNECTIONS',
              project: this.project.id,
              as: 'connections',
              columns: [
                {
                  name: 'to_component',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.component,
                    },
                  ],
                },
                {
                  name: 'from_component',
                  conditions: this.components.map((c) => {
                    return {
                      operator: '=',
                      value: c.id,
                    };
                  }),
                },
                {
                  name: 'type',
                },
              ],
            },
          ],
        });
        this.connectionsTableId = connections.id;

        this.connections = connections.records;
        this.connections.forEach((c) => {
          c.from_component = this.components.find(
            (x) => x.id === c.from_component
          );
          c.from_component.progress = this.getComponentProgress(
            c.from_component
          );
          c.type = connectionTypes.find((t) => t.type === c.type);
        });
      } catch (e) {}
      this.connectionsLoading = false;
    },
    getComponentProgress(component) {
      const propertiesToCheck = [
        'product_lvl',
        'component_function',
        'component_additional_function',
        'technical_lifespan',
      ];
      let filledProps = 0;

      for (let i = 0; i < propertiesToCheck.length; i++) {
        let key = propertiesToCheck[i];
        if (component[key] !== null) {
          filledProps++;
        }
      }
      // calculating percentage
      return (filledProps / propertiesToCheck.length) * 100;
    },
    async saveConnection() {
      this.connectionsLoading = true;
      try {
        let connection = await createRecord({
          project: { id: this.project.id },
          table: { id: this.connectionsTableId },
          record: {
            to_component: this.focusedComponent.id,
            from_component: this.newConnectionComponent.id,
            type: this.newConnectionType.type,
          },
        });
        connection.from_component = this.components.find(
          (x) => x.id === connection.from_component
        );
        connection.from_component.progress = this.getComponentProgress(
          connection.from_component
        );

        connection.type = connectionTypes.find(
          (t) => t.type === connection.type
        );
        this.connections.push(connection);
        this.newConnectionMenu = false;
        this.newConnectionType = '';
        this.newConnectionComponent = '';
      } catch (e) {}
      this.connectionsLoading = false;
    },
    async deleteConnection(connectionId) {
      this.connectionsLoading = true;
      try {
        await deleteRecord(connectionId, {
          table: { id: this.connectionsTableId },
          project: { id: this.project.id },
        });
        this.connections = this.connections.filter(
          (c) => c.id !== connectionId
        );
      } catch (e) {}
      this.connectionsLoading = false;
    },

    async deleteComponent() {
      this.componentsLoading = true;
      try {
        let { connections } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_COMPONENTS_CONNECTIONS',
              project: this.project.id,
              as: 'connections',
              columns: [
                {
                  name: 'to_component',
                },
                {
                  name: 'from_component',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.component,
                    },
                  ],
                },
                {
                  name: 'type',
                },
              ],
            },
          ],
        });

        const records = [
          ...new Set([
            ...this.connections.map((c) => c.id),
            ...connections.records.map((c) => c.id),
          ]),
        ];
        if (records.length > 0) {
          await deleteRecords({
            project: {
              id: this.project.id,
            },
            table: {
              id: this.connectionsTableId,
            },
            records: records,
          });
        }

        await deleteRecord(this.focusedComponent.id, {
          project: {
            id: this.project.id,
          },
          table: {
            id: this.componentsTableId,
          },
        });

        this.components = this.components.filter(
          (c) => c.id !== this.focusedComponent.id
        );
        this.focusedComponent = null;
        this.componentsListIndex = null;
      } catch (e) {
        console.log(e);
      }
      this.componentsLoading = false;
    },
  },
};
</script>

<template>
  <div class="px-5">
    <div class="background-white d-flex" style="width: 250px">
      <v-list class="d-flex flex-column full-width" subheader two-line>
        <div class="subheader">
          1. Onderdelen
          <v-spacer />
          <v-menu
            v-model="newComponentMenu"
            :close-on-content-click="false"
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <div class="background-white pa-1">
              <v-text-field
                v-model="newComponentName"
                autofocus
                class="mb-2"
                filled
                hide-details
                placeholder="Naam"
                single-line
                @keydown.enter="saveComponent"
              />
            </div>
          </v-menu>
        </div>
        <v-progress-linear
          v-show="componentsLoading"
          color="primary"
          height="5"
          indeterminate
        />
        <v-list-item-group
          v-model="componentsListIndex"
          class="flex-grow-1 overflow-y-auto px-2"
          color="primary"
        >
          <v-list-item
            v-for="component in components"
            :key="component.id"
            :style="{
              'border-left':
                component.progress > 0 && component.status !== 'completed'
                  ? 'solid 5px orange'
                  : component.status === 'created'
                    ? 'solid 5px red'
                    : 'solid 5px green',
            }"
            class="mb-2"
            @click="focusedComponent = { ...component }"
          >
            <span class="fs-16 mr-2">{{ component.nr }}.</span>
            <v-list-item-content>
              <v-list-item-title>{{ component.name }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ component.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="component.progress > 0 && component.status !== 'completed'"
            >
              <v-progress-circular
                :value="component.progress"
                color="primary"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <div class="d-flex flex-column pl-5 flex-grow-1">
      <div
        class="background-white full-width d-flex mb-5"
        style="min-height: 300px"
      >
        <div class="d-flex flex-column flex-grow-1 pa-5">
          <div class="subheader">{{ focusedCrossSection?.title }}</div>
        </div>

        <v-dialog style="height: 90vh" width="90vw">
          <template #activator="{ on, attrs }">
            <v-img
              :src="`data:${focusedCrossSection?.image?.mimetype};base64, ${focusedCrossSection?.image?.file}`"
              :style="{
                background: focusedCrossSection?.image ? '' : 'lightgrey',
              }"
              class="my-5 mr-5 radius-8 c-pointer"
              contain
              max-height="300"
              max-width="500"
              style="position: relative"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-img
            :src="`data:${focusedCrossSection?.image?.mimetype};base64, ${focusedCrossSection?.image?.file}`"
            class="radius-8"
            contain
          />
        </v-dialog>
      </div>
      <div
        v-if="focusedComponent"
        class="d-flex flex-grow-1 background-white d-flex pa-5"
        style="height: 0"
      >
        <div class="overflow-y-auto" style="max-width: 70%">
          <v-list subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >1. Benaming
                  <v-spacer />

                  <v-btn
                    v-if="focusedComponent.status !== 'completed'"
                    :disabled="focusedComponent.progress !== 100"
                    color="primary"
                    small
                    @click="completeComponent"
                  >
                    <v-progress-circular
                      v-if="focusedComponent.progress !== 100"
                      v-model="focusedComponent.progress"
                      class="mr-2"
                      color="primary"
                      size="20"
                    />
                    Complete
                  </v-btn>
                  <v-btn
                    v-if="focusedComponent.status === 'completed'"
                    :color="editMode ? 'primary' : ''"
                    icon
                    @click="editMode = !editMode"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-menu :close-on-content-click="false" right>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <div class="background-white pa-1">
                      <v-list-item style="max-width: 400px">
                        <v-list-item-content>
                          <v-list-item-title
                            >Weet je zeker dat je dit onderdeel wilt
                            verwijderen?
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Alle verbindingen zullen verloren gaan
                          </v-list-item-subtitle>
                          <v-btn
                            color="primary"
                            elevation="0"
                            small
                            @click="deleteComponent"
                            >verwijder
                          </v-btn>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-menu>
                </v-list-item-title>

                <v-list-item-subtitle
                  >Geef extra informatie aan over het onderdeel
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input class="mr-5" label="Naam" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="focusedComponent.name"
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input is-optional label="Beschrijving" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="focusedComponent.description"
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >2. Productniveau
                  <v-menu offset-x open-on-hover>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-information-box-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card max-width="600">
                      <v-card-text>
                        <p>
                          Deze indicator analyseert het assemblageproces op de
                          bouwplaats. Het is belangrijk om onderscheid te maken
                          tussen materiaalverwerking direct op de bouwplaats,
                          assemblage van individuele elementen op de bouwplaats
                          en assemblage van prefab componenten/systemen op de
                          bouwplaats.
                          <br />
                          Minder werk op de bouwplaats tijdens montage/demontage
                          resulteert in minder beschadiging van bouwonderdelen
                          tijden het montage-/demontageproces en een sneller
                          montage-/demontageproces.
                          <br /><br />Overzicht van de verschillende product
                          niveaus (zie illustratie hieronder)
                        </p>
                        <v-img
                          contain
                          max-height="300"
                          max-width="500"
                          src="./assets/assembly_lvl.png"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-title>
                <v-list-item-subtitle
                  >Geef aan of het onderdeel los materiaal, een element uit één
                  stuk of deel van een samengestelde systeem/component is.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input label="Productniveau" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.product_lvl"
                    :items="
                      componentsColumns.find((c) => c.name === 'product_lvl')
                        .options_value
                    "
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >3. Onderdeelfunctie
                  <v-menu offset-x open-on-hover>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-information-box-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card max-width="600">
                      <v-card-text>
                        <p>
                          Hoe minder relaties een onderdeel heeft, des te
                          makkelijker het is om het onderdeel te recupereren.
                          Een onderdeel met een basis/dragende of intermediaire
                          functie zorgt er voor dat onderdelen onderling niet
                          direct verbonden zijn maar via een intermediair
                          tussenstuk, waardoor ze sneller gedemonteerd kunnen
                          worden zonder elkaar te raken.
                        </p>
                        <v-img
                          contain
                          max-height="300"
                          max-width="500"
                          src="./assets/component_function.png"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: normal"
                  >Vul in of het onderdeel al dan niet een base/dragende of
                  intermediaire functie heeft binnen een bouwdeel. En verifieer
                  of er andere functies zijn anders dan afwerking (installaties,
                  niet dragende scheiding).
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input
                class="mr-5"
                label="Onderdeelfunctie"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.component_function"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'component_function'
                      ).options_value
                    "
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input
                label="Bijkomende onderdeelfunctie(s) anders dan afwerking"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.component_additional_function"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'component_additional_function'
                      ).options_value
                    "
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >4. Gebouwfunctie
                  <v-menu open-on-hover top>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-information-box-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card max-width="600">
                      <v-card-text>
                        <p>
                          Gebouwfunctie is niet verplicht voor deze
                          berekening.<br />
                          Gebouwfuncties zijn:<br />
                          • dragende/load bearing functie (zoals fundering,
                          constructieve wanden, kolommen, balken, vloeren),<br />
                          • omhullende/enclosing functie (zoals gevels en
                          daken), scheidende/partitioning functies (zoals
                          binnenwanden),<br />
                          • afwerkings-/finishing functie (zoals verlaagde
                          plafond, vloerafwerking),<br />
                          • installatie/servicingfunctie (zoals elektra, riool,
                          klimaatinstallaties)<br />
                          <br />
                          Zorg er voor dat onderdelen monofunctioneel zijn in
                          plaats van dat ze meerdere functies vervullen.
                        </p>
                        <v-img
                          contain
                          max-height="300"
                          max-width="500"
                          src="./assets/building_function.png"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: normal"
                  >Vul in de gebouwfunctie van het onderdeel. Voeg toe of het
                  meerdere functies vervult, en in geval van component/system of
                  het ook direct voor andere functies gebruikt kan worden
                  (generiek component/system).
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input
                class="mr-5"
                is-optional
                label="Gebouwfunctie"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-text-field
                    v-model="focusedComponent.building_function"
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input
                class="mr-5"
                is-optional
                label="Bijkomende gebouwfuncties"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.building_additional_function"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'building_additional_function'
                      ).options_value
                    "
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input
                is-optional
                label="Generiek component/systeem"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.generic_component"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'generic_component'
                      ).options_value
                    "
                    :readonly="!editable"
                    dense
                    filled
                    hide-details
                    single-line
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >5. Levensduur
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: normal"
                  >Vul de technische levensduur van alle onderdelen in (zie
                  werkblad 'appendix lifecycle'), en de bouwdatum indien deze
                  afwijkt van de algemene informatie.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input
                class="mr-5"
                is-optional
                label="Afwijking van bouwdatum"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-menu
                    v-model="componentConstructionDeviationDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="focusedComponent.construction_date_deviation"
                        clearable
                        dense
                        filled
                        prepend-icon="mdi-calendar"
                        readonly
                        single-line
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="focusedComponent.construction_date_deviation"
                      @change="updateComponent"
                      @input="componentConstructionDeviationDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </ant-input>

              <ant-input
                label="Technische levensduur (jaren)"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.technical_lifespan"
                    :items="lifespanOptions()"
                    :readonly="!editable"
                    :return-object="false"
                    dense
                    filled
                    hide-details
                    item-text="label"
                    item-value="id"
                    single-line
                    type="number"
                    @change="updateComponent"
                  >
                    <template #item="{ item }">
                      {{ item.label }} ({{ item.years }})
                    </template>
                  </v-select>
                </template>
              </ant-input>
            </div>
          </v-list>
        </div>
        <div class="flex-grow-1 overflow-y-auto">
          <v-list class="px-2" subheader two-line>
            <div class="subheader-small">
              6. Type verbindingen
              <v-menu open-on-hover top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-information-box-outline</v-icon>
                  </v-btn>
                </template>

                <v-card max-width="600">
                  <v-card-text>
                    <p>
                      Selecteer de juiste type verbindingen uit drop-down menu.
                    </p>
                    <v-img
                      contain
                      max-height="300"
                      max-width="500"
                      src="./assets/connections.jpg"
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer />
              <v-menu
                v-model="newConnectionMenu"
                :close-on-content-click="false"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-btn :disabled="!editable" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <ant-input label="Onderdeel" top-margin="mt-2">
                      <template #input-field>
                        <v-combobox
                          v-model="newConnectionComponent"
                          :items="components"
                          autofocus
                          filled
                          hide-details
                          item-text="name"
                          item-value="id"
                          placeholder="onderdeel"
                          single-line
                        >
                          <template #item="{ item }">
                            <span class="font-weight-bold mr-2">{{
                              item.nr
                            }}</span>
                            {{ item.name }}
                          </template>
                        </v-combobox>
                      </template>
                    </ant-input>
                    <ant-input label="Type" top-margin="mt-0">
                      <template #input-field>
                        <v-combobox
                          v-model="newConnectionType"
                          :items="connectionTypes()"
                          class="mb-2"
                          filled
                          hide-details
                          item-text="type"
                          item-value="type"
                          placeholder="type"
                          single-line
                        >
                          <template #item="{ item }">
                            <span class="font-weight-bold mr-2">{{
                              item.type
                            }}</span>
                            {{ item.label }}
                          </template>
                        </v-combobox>
                      </template>
                    </ant-input>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" small @click="saveConnection"
                      >Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
            <v-progress-linear
              v-show="connectionsLoading"
              color="primary"
              height="5"
              indeterminate
            />
            <v-list-item-group
              class="flex-grow-1 overflow-y-auto px-2"
              color="primary"
            >
              <v-list-item
                v-for="connection in connections"
                :key="connection.id"
                :style="{
                  'border-left':
                    connection.from_component.progress > 0 &&
                    connection.from_component.progress < 100
                      ? 'solid 5px orange'
                      : connection.from_component.status === 'created'
                        ? 'solid 5px red'
                        : 'solid 5px green',
                }"
                class="mb-2"
                @click="focusedComponent = { ...connection.from_component }"
              >
                <span class="fs-16 mr-2"
                  >{{ connection.from_component.nr }}.</span
                >
                <v-list-item-content>
                  <v-list-item-title
                    >{{ connection.from_component.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ connection.type.label }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <div class="d-flex align-center">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-chip
                          class="mr-2"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          >{{ connection.type.type }}
                        </v-chip>
                      </template>
                      <span>{{ connection.type.label }}</span>
                    </v-tooltip>
                    <v-btn icon @click="deleteConnection(connection.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subheader {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: var(--v-primary-base);
  font-size: 20px;
}

.subheader-small {
  display: flex;
  align-items: center;
  color: var(--v-primary-base);
}
</style>
